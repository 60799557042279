@media (max-width: 768px) {
    .upper-container {
        position: fixed;
        top: 0;

        transition: width .5s ease-in-out;

        &.level-0, &.level-1, &.level-2 {
            width: 100%;
        }

        height: auto;

        z-index: 20;
    }

    .picto-navbar {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;

        > div {
            transition: width .5s ease-in-out;
        }

        width: 100%;
        height: auto;
    }

    .pictogramsD-wrapper {
        width: 100%;
        height: auto;

        background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0), 
            rgba(0, 0, 0, 0.7), 
            rgba(0,0,0,1)
        );
    }

    .pictogramsD {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        width: 85vw;
        height: 80px;

        margin: auto;
    }

    .logoD-wrapper {
        width: 100%;
        background-color: black;
    }

    .logoD {
        margin: auto;
        
        width: 90%;
        height: 100px;

        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        border-bottom: 1px solid white;
    }

    #logo {
        width: 100%;
        
        background-repeat: no-repeat;
        background-position: center;

        height: 90px;

        margin-right: 10px;

        border: none;
        outline: none;
    }

    #logo-item {
        text-decoration: none;
        color: transparent;
        background-color: transparent;
    }
}