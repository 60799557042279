@media (min-width: 768px) {
    .whyD {
        margin-top: 100px;
    }
}

.whyD {
    width: 90%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    
    align-items: center;
}

#Why {
    height: auto;
}