@keyframes carousel {
    0% { transform: translate3d(0, 0, 0); }
    100% { transform: translate3d(-50%, 0, 0); }
}

@-webkit-keyframes carousel {
    0% { -webkit-transform: translate3d(0, 0, 0); }
    100% { -webkit-transform: translate3d(-50%, 0, 0); }
}

@-moz-keyframes carousel {
    0% { -moz-transform: translate3d(0, 0, 0); }
    100% { -moz-transform: translate3d(-50%, 0, 0); }
}

@mixin blackGradient {
    background: linear-gradient(-90deg, transparent, rgba(0, 0, 0, 1));
}

.imagecarousel-wrapper {
    position: relative;
    display: flex;
    flex-direction: row;

    margin: auto;

    width: 90%;
    overflow-x: hidden;

    white-space: nowrap;

    &::before, &::after {
        @include blackGradient;
        content: "";

        position: absolute;

        display: inline-block;

        height: 100%;
        z-index: 1;
    }

    &::before {
        left: 0;
    }

    &::after {
        right: -1px;
        rotate: 180deg;
    }
}

.image-carousel {
    display: flex;

    gap: 10px;

    width: min-content;
    height: min-content;
    
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;

    will-change: transform, -webkit-transform, -moz-transform;
    
    animation: carousel 25s linear infinite;
    -webkit-animation: carousel 25s linear infinite;
    -moz-animation: carousel 25s linear infinite;
}