@media (min-width: 768px) {
    #MainView {
        height: 100vh;

        position: absolute;
        top: 0;
    }

    .backgroundImage {
        position: absolute;

        width: 100%;
        height: 120vh;

        object-fit: cover;

        opacity: 80%;

        z-index: 0;
    }
}