#sportView {
    display: flex;
    flex-direction: row;
    justify-content: center;

    align-items: center;

    z-index: 0;
}

#sport {
    width: 100%;
    height: 100%;

    display: grid;

    grid-template-columns: auto auto;

    align-items: center;
}

#sport>* {
    justify-self: center;
}

#sportI {
    width: 100%;
    /* height: auto; */

    min-width: 200px;
}

#sportSpan {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 80%;
}