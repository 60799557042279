#Models {
    position: relative;

    width: 100%;

    display: grid;

    justify-content: space-around;

    height: calc(90% - 100px);
}

.Model {
    position: inherit;

    transition: all .5s;

    margin-bottom: 0px;
    margin-top: auto;

    max-width: 80%;
    max-height: 80%;
}

.Model:hover {
    transform-origin: center;

    transform: scale(1.2);
}

.model-with-description {
    position: relative;

    font-size: 2em;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    color: white;
    align-items: center;
    
    max-width: 100%;
    max-height: calc(80vh - 100px);
}

.model-with-description > span {
    margin-bottom: 0;
    margin-top: auto;

    max-height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.sub-header {
    font-size: large;
}