@media (max-width: 768px) {
    .backgroundImage {
        width: 100vw;
        height: 100vh;

        object-fit: cover;

        opacity: 80%;

        z-index: 0;
    }
}