@media (max-width: 768px) {
    #trailer-subview-2-span-0 {
        grid-row: 1 / span 1;
    }

    #trailer-subview-2-span-1 {
        grid-row: 3 / span 1;
    }

    #trailer-subview-2-span-2 {
        grid-row: 5 / span 1;
    }

    #trailer-subview-2-span-3 {
        grid-row: 7 / span 1;
    }

    #trailer-subview-2-span-4 {
        grid-row: 9 / span 1;
    }
}