@use 'src/common/colors';

.shop-body {
    overflow-x: hidden;

    background-color: colors.$dark-theme-primary;;

    position: absolute;
    top: 0px;
    left: 0px;

    width: 100%;
    height: auto;

    z-index: -1;
}

.page {
    width: 100%;
    height: auto;

    display: flex;
    flex-direction: column;
    align-items: center;

    margin: 0;
    padding: 0;

    left: 0px;
    top: 0px;

    background-color: colors.$dark-theme-primary;
}

.shop-footer {
    font-size: 1.5em;
    margin: 30px 0 100px;
}

.shop-footer a {
    font-size: 2em;
    text-decoration: none;
    color: colors.$dark-theme-secondary;
}