@media (max-width: 768px) {
    #Showroom {
        overflow-x: hidden;

        /* margin-top: 85vh; */
        /* padding: 10px 0 0 0; */

        height: auto;
    }

    #Models {
        grid-template-columns: auto;
        /* grid-template-rows: 20% 60% 20%; */
    }

    #Models > a {
        /* grid-column-start: 2; */
        margin: 20px 0;
    }

    #model-with-description-unit {
        grid-row: 1 / 2;
    }

    .Model { width: 100%; }
}