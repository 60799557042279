.sliding-panel {
    position: relative;

    width: 100vw;
    height: 100%;

    // background-color: white;
}

.sliding-panel-image {
    width: 100%;
    height: 100%;

    object-fit: cover;
    object-position: center;
}

.sliding-panel-button {
    position: absolute;

    bottom: 7%;
    left: 50%;

    transform: translate(-50%, -50%);
}

.button-38 {
    background-color: #FFFFFF;
    border: 0;
    border-radius: .5rem;
    box-sizing: border-box;
    
    color: #111827;
    
    font-size: 1em;
    line-height: 1.25rem;
    padding: .75rem 1rem;
    
    text-align: center;
    text-decoration: none #D1D5DB solid;
    text-decoration-thickness: auto;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);

    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}

.button-38:hover {
    background-color: rgb(249,250,251);
}

.button-38:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
}

.button-38:focus-visible {
    box-shadow: none;
}