@media (max-width: 768px) {
    .forDescription {
        width: 40px;
    }

    .forDescription>p {
        margin: 0;
        height: 1em;
    }

    .subpage-button {
        width: auto;
        height: 40px;
    }

    .picto-nav-item:not(#logo):not(.vn) {
        width: auto;
        height: 100%;

        object-fit: contain;
    }

    .forDescription.active {
        color: var(--orange);
    }
}