body {
  margin: 0;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: var(--grey);
}

:root {
  --orange: #ff8500;
  --grey: black;
  --blue: #007bff;
    /* #2c2c2c */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Fonts */
.daorffo-font-regular * {
  font-family: "Barlow", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.daorffo-font-medium * {
  font-family: "Barlow", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.daorffo-font-bold * {
  font-family: "Barlow", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.font-white {
  color: white;
}

.font-black {
  color: black;
}
/* End Fonts */

.sectionH {
  font-size: 2em;
  font-size: normal;
}

.filtering {
  position: absolute;

  top: inherit;
  left: inherit;

  width: 100%;
  height: 100%;

  z-index: -1;
}

.textSpan {
  font-size: 1.2em;
}

.dark {
  background-color: rgba(0, 0, 0, 0.8);
}