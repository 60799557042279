@media (max-width: 768px) {
    .trailer-layout {
        height: 100vh;
        grid-template-columns: 100%;
        gap: 20px;

        padding-top: 100px;
    }

    .trailer-layout-left {
        display: none;
    }

    .trailer-layout-mid {
        grid-column: 1 / span 1;
    }
    
    .trailer-layout-right {
        grid-column: 1 / span 1;
    }
}