#main-body {
    background-color: var(--grey);
    
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;

    scroll-behavior: smooth;

    z-index: -1;
}

.snap-scroll-start {
  scroll-snap-align: start;
}

.snap-scroll-center {
  scroll-snap-align: center;
}

#topr {
    margin-top: 5%;
}