.construction-alert {
    width: 100%;
    height: auto;

    position: absolute;

    display: flex;
    flex-direction: row;
    justify-content: center;

    align-items: center;

    top: 40vh;

    background-color: rgba(0,0,0,0.7);
}

.construction-alert > p {
    text-align: center;

    font-size: 1.5em;
    color: var(--orange);
}