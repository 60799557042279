@media (max-width: 768px) {
    .navbar {
        position: fixed;
        left: 0;
        top: 0;

        transition: .5s;

        padding-top: 100px;

        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        font-size: 1.1em;

        height: calc( 100vh - 100px );
    }

    .upperLogoD {
        justify-content: flex-end;
    }
    
    #upperLogo {
        height: 80%;

        margin-right: -10px;
    }

    .nav-closed {
        width: 0;
        overflow-x: hidden;
    }

    .nav-opened { 
        width: 50vw; 
    }

    .nav-opened {
        .light & {
            border-right: 1px solid var(--blue);
            background-color: white
        }

        .dark & {
            border-right: 1px solid var(--orange);
            background-color: var(--grey);
        }
    }

    .desktop-nav-horisontal .nav-dropdown {
        flex-direction: column;
        align-items: center;
    }

    .hamburgerD {
        position: fixed;
        top: 0;
        left: 0;

        display: flex;
        flex-direction: column;
        justify-content: center;

        align-items: center;

        height: 100px;
        width: 100px;

        z-index: 100;
    }

    .hamburgerD-closed {
        left: 0;
    }

    .hamburgerD-opened {
        left: 25vw;
        transform: translate(-50%, 0);
    }

    #hamburger {
        height: 1.5em;
        width: auto;

        object-fit: cover;
    }
}