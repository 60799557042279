.small-view {
    width: 100%;
    height: 100vh;

    display: flex;
    flex-direction: column;
    align-items: center;

    margin: 0;
    padding: 0;

    left: 0px;
    top: 0px;

    background-color: var(--grey);
}