#trailer-body {
    width: 100%;
    height: auto;

    background-color: var(--grey);
    
    position: absolute;
    top: 0px;
    left: 0px;

    z-index: -1;
}

.trailer-layout {
    width: 100%;
    min-height: 100%;

    display: grid;

    > div {
        transition: width .5s ease-in-out;
    }
}

.trailer-layout-left {
    grid-column: 1 / span 1;
    grid-row: 1 / span 999;
}

.floatingDiv {
    position: sticky;
    top: 0;
}

.trailer-subview {
    width: 100%;
    height: 100%;

    * {text-align: start;}
}

.h3 {
    color: var(--orange);
    font-size: 2em;
}