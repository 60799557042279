.view-header {
    width: 80%;
    height: 100px;

    min-height: 100px;

    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: end;
    justify-content: center;

    z-index: 11;

    font-size: 3em;
}