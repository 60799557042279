@media (min-width: 768px) {
    #Showroom {
        margin-top: 100vh;
    }

    #Models {
        margin: auto;
        grid-template-columns: 30% 40% 30%;

        width: 90%;
    }

    .Model   {
        max-width: 100%;
        max-height: 80% !important;
        object-fit: contain;
    }
}