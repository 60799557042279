@use 'src/common/colors';

.shop-list-item {
    width: 90%;

    margin: 30px 5%;

    border: solid 1px white;
    border-radius: 15px;

    background-color: colors.$dark-theme-tertiary;
    color: white;
}

.shop-list-item-image {
    width: 100%;
    height: 300px;

    padding: 30px 0;

    display: flex;
    align-items: center;
    justify-content: center;
}

.shop-list-item-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.shop-list-item-header-container {
    width: 100%;
    display: flex;
}

.shop-list-item-title {
    display: flex;
    flex-direction: column;
    font-size: 1.8em;
    font-weight: bold;
}

.shop-list-item-price {
    display: flex;
    flex-direction: column;
    font-size: 1.5em;
}

.shop-list-item-button-link {
    background-color: colors.$dark-theme-tertiary;
    color: white;
    border: 1px solid white;
    border-radius: 7px;

    padding: 10px;

    font-size: 1.2em;
}

.shop-list-item-button-link:hover {
    background-color: white;
    color: colors.$dark-theme-tertiary;
    border: 1px solid colors.$dark-theme-tertiary;
    border-radius: 7px;

    padding: 10px;

    font-size: 1.2em;
}
.shop-list-item-button-link a {
    color: white;
    text-decoration: none;
}

.shop-list-item-button-link a:hover {
    color: colors.$dark-theme-tertiary;
    text-decoration: none;
}

.span-list-item-description {
    line-height: 150%;
    font-size: 1.2em;
}

/* As justified continuous text */
/* .span-list-item-description {
    text-align: justify;
    text-justify: inter-word;
} */

/* As bullet lsit */
.span-list-item-description ul li { text-align: left; }