@media (max-width: 768px) {
	.shop-list-item {
		display: flex;
		flex-direction: column;
	}

	.shop-list-item-image img {
		height: 100%;
		width: 100%;
		object-fit: contain;
	}

	.shop-list-item-header-container {
		justify-content: center;
		flex-direction: column;
	}

	.shop-list-item-title {
		justify-content: center;
	}

	.shop-list-item-price {
		justify-content: center;
		margin: 10px 0;
	}

	.shop-list-item-description {
		padding: 0 30px 50px;
	}

	.shop-list-item-HR-wrapper {
		margin-top: 20px;
		width: 100%;
	}

	.shop-list-item-HR {
		width: 90%;
	}
}