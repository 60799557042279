@media (min-width: 768px) {
    .upper-container {
        position: fixed;
        top: 0;
        left: 100px;
        margin-top: 0;

        &.level-0 {
            width: calc(100% - 100px);
        }

        &.level-1 {
            width: calc(.5*(100% - 100px));
        }

        &.level-2 {
            width: calc(.2*(100% - 100px))
        }

        height: auto;

        &.transparent {
            background-color: transparent;
        }

        &:not(.transparent) {
            background-color: #000000;
        }
    }

    .picto-navbar {
        display: flex;
        flex-direction: row;
        justify-content: space-around;

        > div {
            transition: width .5s ease-in-out, opacity .5s ease-in-out;
        }

        border-bottom: 1px solid white;

        width: 98%;
        height: 130px;

        &.level-0 {
            >:first-child { 
                width: 250px; 
                opacity: 1;
            }
        }

        &.level-1, &.level-2 {
            >:first-child { 
                width: 0%;
                opacity: 0; 
            }
        }
    }

    .pictogramsD-wrapper {
        width: 100%;
        height: auto;
    }

    .pictogramsD {
        position: relative;

        width: 100%;
        height: 100%;
        
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;

        padding-top: 5px;
    }

    .logoD-wrapper {
        height: auto;
        width: 250px;

        position: relative;

        margin: auto auto auto 0;

        margin-left: 0;
        margin-right: auto;
    }

    .logoD {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: start;

        height: auto;
        width: 100%;
    }

    #logo-item {
        height: auto;
        width: 100%;
    }

    #logo {
        content: url("/../public/resources/TrailerPage/PictoNavbar/Logo/Desktop/icon-inactive.png");
        &:hover {content: url("/../public/resources/TrailerPage/PictoNavbar/Logo/Desktop/icon-active.png");}

        height: 100px;
        width: min-content;
    }

    .level-2 .picto-navbar-arrow {
        display: block;

        height: 100px;
    }
}