@use 'src/common/colors';

.pointerCursor { cursor: pointer; }

.nav-dropdown-opened {
    height: calc(5 * (1.1em) + 50px);
}

.nav-dropdown-closed {
    height: 0;
}

.main-navbar-footer {
    width: 90%;
    height: 30%;

    margin: auto auto 20px auto;

    position: relative;
    bottom: 0;

    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.main-navbar-footer-media-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    gap: 10px;

    height: 100%;
    width: 50%;
}

.main-navbar-footer-media {
    width: 100%;
    min-height: 0;

    & > img {
        max-width: 100%;
        max-height: 100%;

        object-fit: contain;
    }
}

.main-navbar-footer-logo-wrapper {
    width: 50%;
    height: 100%;
}

.main-navbar-footer-logo {
    display: block;

    width: 100%;
    height: 100%;

    &>img {
        width: 100%;
        height: 100%;

        object-fit: contain;
    }
}