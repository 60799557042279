.forDescription {
    font-size: 1.1em;
    color: rgba(255, 255, 255, 0.3);

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    & * {
        transition: all .5s ease-in-out;
    }
}

.forDescription:hover {
    color: var(--orange);
}