#applicabilityView {
    display: flex;
    flex-direction: row;
    justify-content: center;

    align-items: center;

    z-index: 0;
}

#applicability {
    display: grid;

    grid-template-columns: auto auto;

    align-items: center;
}

#applicability>* {
    justify-self: center;
}

#applicationsI {
    width: 20%;
    height: auto;

    min-width: 200px;
}

#applicationsSpan {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 80%;
}

#applicationsSpan>h3 {
    font-size: 2em;
}