#logo {
    background-size: cover;
    width: 200px;
}

.upper-container {
    z-index: 1;
}

.picto-navbar-arrow {
    display: none;
}