.summarisingSpan {
    font-weight: bold;
    color: var(--orange);
}

#SummarisingView {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

#SummarisingViewDescriptionContainer {
    width: 100%;
    height: 100%;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}