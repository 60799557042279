@media (min-width: 768px) {
    .subgallery {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .gallery-item-wrapper {
        height: 100vh;
        width: 100%;

        object-fit: cover;
        object-position: center;

        margin-left: auto;
        margin-right: 0;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    
    .gallery-item-filler {
        margin-left: 0;
        margin-right: auto;
    }

    .gallery-item {
        object-fit: cover;
        object-position: center;
    }
}