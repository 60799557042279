.floating-span {
    display: block;
    top: 130px;

    align-self: start;

    position: -webkit-sticky;
    position: sticky;      
    
    height: min-content;
    padding: 10px;
}

.text-span {
    font-size: 1.2em;
}

.floating-span-container-filler {
    height: 130px;
}