@media (max-width: 768px) {
    #trailer-subview-1-span-0 {
        grid-row: 1 / span 1;

        width: 100%;
        height: 100%;
    }

    #trailer-subview-1-span-1 {
        grid-row: 3 / span 1;

        width: 100%;
        height: 100%;
    }
}