@media (min-width: 768px) {
    body:has(#main-body) {
        overflow: hidden;
    }

    #main-body {
        height: 100%;
  
        scroll-snap-type: y mandatory;
        overflow-y: scroll;
    }
}
