.floating-navbar-container {
    position: relative;
    
    width: 100px;
    height: 100%;
    
    z-index: 1;
}

.floating-navbar {
    position: fixed;

    top: 200px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    width: 100px;
}