.floating-navbar-item {
    position: relative;

    width: 100%;
    height: 100px;

    padding: auto;

    display: flex;
    justify-content: center;
    align-items: center;
}

.floating-navbar-item-icon {
    width: 50%;
    height: 50%;

    display: flex;
    justify-content: center;
    align-items: center;

    object-fit: contain;
}