@media (min-width: 768px) {
    .level-2 .forDescription:not(.active) {
        display: none;
    }

    .forDescription {
        &.active {
            & .subpage-button {
                height: 80px;
            }
    
            & p {
                height: 0;
                opacity: 0;
            }
        }

        &>p {
            margin-top: 0.5em;
            height: 0.5em;
        }
    }

    .subpage-button {
        width: auto;
        height: 60px;

        display: flex;
    }

    .subpage-button img {
        margin: auto;
    }

    .picto-nav-item:not(#logo):not(.vn) {
        /* width: 100%; */
        height: 100%;

        object-fit: contain;
    }
}