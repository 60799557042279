@use 'src/common/colors';

.soon-info-tip-children-wrapper {
	pointer-events: none;
}

.soon-div {
	color: colors.$dark-theme-secondary;
	background-color: rgba($color: colors.$dark-theme-tertiary, $alpha: .5);

	border-radius: 5px;
	padding: 5px;

	font-size: 1rem;
}