@media (min-width: 768px) {
	#unit-main-page {
		padding-top: 10vh;
	}
	
	#u1-image-and-logo-container {
		grid-template-columns: 60% 40%;
		grid-template-rows: auto;
	}
	
	#u1-parameters-container {
		grid-template-rows: auto;
		grid-template-columns: 30% 70%;
	}
	
	.u1-parameters-description-parameters-horisontal-container {
		grid-template-columns: 90% 10%;
	}

	#u1-description-container {
		grid-template-columns: 10% 90%;
		grid-template-rows: auto auto auto;
	}
	
	#u1-description-logo-container {
		grid-row: 1;
		grid-column: 1;
	}
	
	#u1-logo {
		width: 100%;
		height: 100%;
		
		object-fit: contain;
	
		content: url(../../../../public/resources/UnitPage/U1-image-and-logo-container/U1-Logo-short.png);
	}
	
	#u1-description-header {
		grid-row: 1;
		grid-column: 2;
	}
	
	#u1-description-1 {
		grid-row: 2;
		grid-column: 2;
	}

	#u1-description-2 {
		grid-row: 3;
		grid-column: 2;
	}
	
	#u1-drawbar-container {
		grid-template-rows: auto auto;
		grid-template-columns: auto;
	}
	
	#u1-features-container {
		grid-template-rows: auto auto;
		grid-template-columns: 60% 40%;
	
		margin-top: 10%;
	}
	
	#u1-features-image-container {
		grid-row: span 2;
		grid-column: 1;
	}
	
	#u1-features-description-container {
		justify-content: flex-start;
	
		grid-row: 1;
		grid-column: 2;
	}
	
	#u1-features-list-container {
		grid-row: 2;
		grid-column: 2;
	}
	
	#u1-summary-container {
		grid-template-rows: 40% 60%;
		grid-template-columns: 50% 50%;
	}
	
	#u1-summary-image-container {
		grid-row: span 4;
		grid-column: 1;
	}
	
	#u1-summary-image {
		width: 100%;
		height: 100%;
	
		object-fit: contain;
	}
	
	#u1-summary-description-container {
		grid-row: 1;
		grid-column: 2;
	}
	
	#u1-summary-price-description-container {
		grid-row: 2;
		grid-column: 2;
	}
	
	#u1-summary-price-description {
		font-size: 4em;
	}
	
	#u1-summary-contact-description-container {
		grid-row: 3;
		grid-column: 2;
	}
	
	#u1-summary-contact-button-container {
		grid-row: 4;
		grid-column: 2;
	}
}